.actionButton {
  height: 40px;
  border-radius: 8px;
  color: #fff;
  background-color: #ff6c00;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #c45300;
  }
  &:active {
    background-color: #ffa664;
  }
}
