.SecondaryButton {
  height: 40px;
  width: auto;
  min-width: 200px;
  border-radius: 8px;
  border: solid 1px #00375a;
  background-color: white;
  font-size: 14px;
  color: #00375a;
  padding: 0px 20px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;

  &:hover {
    background-color: #ccd7de;
  }

  &:active {
    border-color: #335f7b !important;
    background-color: #335f7b;
    color: white;
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    border-color: #33ccff;
    outline: none;
  }

  &:disabled {
    color: #828282;
    border-color: #828282;
  }

  &.alignright {
    margin-left: auto;
  }
  &.alignleft {
    margin-right: auto;
  }
  &.aligncenter {
    margin-left: auto;
    margin-right: auto;
  }
}
