.search-form {
  gap: 15px;
  display: flex;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.05);
  background: #fff;
  transition: all 0.3s ease;
  padding-inline: 15px;

  &.focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  }

  .search-input {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    background: none;
    color: #5a6674;
    border: none;
    appearance: none;
    outline: none;

    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    height: 100%;
  }
}
