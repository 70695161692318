.createOrigin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.18);

  .originIcon {
    margin: 40px 0px;
  }

  .label {
    text-align: center;
    margin: 0px 20px;
  }

  .titleBar {
    display: flex;

    .originIcon {
      max-width: 200px;
      max-height: 50px;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 1.3em;
      margin-left: 32px;
    }
  }

  .subTitleBar {
    text-align: center;
  }

  .createOriginBtn {
    margin-top: 10px;
  }

  &.beforeCreate {
    justify-content: center;
    padding: 30px;

    .originIcon {
      margin: 0px 0px;
    }

    .titleBar {
      margin-bottom: 50px;
    }

    .inputName {
      margin-top: 10px;
    }
  }
}
