.detailedOriginCard {
  flex-grow: 1;
  height: auto;
  width: 600px;
  background-color: white;
  border-radius: 10px;
  margin: 16px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 25px 25px 25px;

  .rowConfigLabel {
    display: flex;
    height: 80px;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: stretch;
    width: stretch;
  }

  .row-utils {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: stretch;
    width: stretch;
  }

  .col-infos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-grow: 1;
  }

  .col-utils {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 200px;
  }

  .originInfos {
    display: flex;
    flex-direction: column;
  }

  .iconConf {
    height: 24px;
  }

  .iconConf:hover {
    opacity: 0.5;
    transition: 0.08s;
    cursor: pointer;
  }

  .configureBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $blue_1;
    border-radius: 4px;
    border: hidden;
    color: white;
    padding: 8px 16px;
    min-height: 40px;
    cursor: pointer;

    &:hover {
      box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.8);
    }

    .configureLabel {
      color: white;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .label-configuration-in-progress {
    font-weight: 500;
    color: orange;
  }

  .label-active {
    font-weight: 500;
    color: #3fa34d;
  }

  .label-inactive {
    font-weight: 500;
    color: red;
  }

  .modifyNameIcon {
    width: 12px;
    height: 12px;
    fill: lightgray;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .editNameInput {
    font-size: 12px;
    background-color: #f4f4f4;
    color: #5a6674;
    height: 20px;
    border: 1px lightgrey solid;
    border-radius: 5px;
    padding: 15px 22px 15px 8px;
    appearance: none;
    outline: none;

    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }

  .editNameBtn {
    border-radius: 5px;
    border: none;
    background-color: #012b38;
    color: white;
    padding: 7px;
    width: 32px;
    height: 32px;
    &:hover {
      cursor: pointer;
      background-color: #56b9dc;
    }
  }

  .loadingEditNameBtn {
    border-radius: 5px;
    border: none;
    background-color: grey;
    color: white;
    padding: 7px;
    width: 32px;
    height: 32px;
  }

  .editNameContainer {
    display: flex;
    width: 230px;
    justify-content: space-between;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .detailedOriginCard {
    width: 100%;
    min-width: unset;
  }

  .detailedOriginCard .col-infos {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .detailedOriginCard .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .detailedOriginCard .col-utils {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .detailedOriginCard .configureBtn {
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
  }

  .detailedOriginCard .configureBtn {
    justify-content: center;
  }

  .detailedOriginCard .rowConfigLabel {
    width: 100%;
    justify-content: center;
    font-size: large;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 769px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 1024px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1216px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1408px) {
}
