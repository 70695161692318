/* Theme colors */
$black: #000;
$white: #fff;
$blue_1: #012b38;
$blue_2: #49a8c2;
$blue_2_filter: brightness(0) saturate(100%) invert(60%) sepia(23%)
  saturate(750%) hue-rotate(141deg) brightness(98%) contrast(93%);
$blue_3: #00a2ce;
$blue_4: #f3fcff;
$grey_1: #232529;
$grey_2: #474a51;
$grey_3: #8e95a1;
$grey_4: #bcc4d2;
$grey_5: #ececec;
$grey_6: #f2f2f2;
$grey_7: #f7f7f7;
$grey_8: #fbfbfb;
$page_bg: #f5f7fb;
$red: #d01c1c;
$red_filter: brightness(0) saturate(100%) invert(30%) sepia(79%) saturate(5127%)
  hue-rotate(346deg) brightness(80%) contrast(106%);
$orange: #ff9900;
$green: #2dd039;
$green_filter: brightness(0) saturate(100%) invert(61%) sepia(97%)
  saturate(470%) hue-rotate(70deg) brightness(92%) contrast(88%);
$transparent_black: rgba(0, 0, 0, 0.05);
$transparent_white: rgba(255, 255, 255, 0.1);
$brand: #4f5456;
