@import '~react-dates/lib/css/_datepicker.css';
@import '~@facilecomm/sbo-icon-font/index.css';

$roboto-font-path: '~roboto-fontface/fonts';
@import '~roboto-fontface/css/roboto/sass/roboto-fontface';
@import 'utils';
@import './color_palette.scss';

/* Redefine Bulma variables */
/* Background color */
$scheme-main: $page_bg;

.todo {
  color: lime;
  font-size: 30px !important;
  font-weight: 700;
  background-color: salmon;
}

@import '~bulma/bulma.sass';

/* Import main component styles */
@import './Project.scss';
@import './Navbar.scss';

/* Import component styles */
@import './components/ViewComponents/ViewComponents.scss';
@import './components/ViewComponents/OriginListing/OriginListing.scss';
@import './components/ViewComponents/OriginListing/OriginCard.scss';
@import './components/ViewComponents/CreateOrigin/CreateOrigin.scss';
@import './components/ViewComponents/EditOrigin/EditOrigin.scss';
@import './components/ViewComponents/OriginTypeListing/OriginTypeListing.scss';
@import './components/ViewComponents/OriginTypeListing/DetailedOriginCard.scss';
@import './components/StepIndicator/StepIndicator.scss';
@import './components/Loader/ValidCheck.scss';
@import './components/Loader/InvalidCheck.scss';
@import './components/FilterBar/filterBar.scss';
@import './components/SearchBar/searchBar.scss';
@import './components/Switch/switch.scss';
@import './components/StepContent/StepContent.scss';
@import './components/StepContent/SCActionButton.scss';
@import './components/StepContent/SCConnTest.scss';
@import './components/SecondaryButton/SecondaryButton.scss';

html,
body,
#root,
.App {
  height: 100%;
  overflow: hidden;
}

.full_content {
  height: calc(100% - 56px);
  display: flex;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.ContentPane {
  width: 100%;
  height: calc(100% - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px 20px 12px 20px;
  flex-wrap: wrap;
  margin: 0;
  align-content: start;
}

.ContentPane.fullheight {
  height: calc(100%);
}

.ContentPane .column {
  padding: 0;
}

.breadcrumb {
  display: flex;
  align-items: center;
}

.breadcrumb a {
  color: $grey_4;
  font-size: 12px;
}

.breadcrumb a:hover {
  color: $grey_3;
  font-size: 12px;
}

.breadcrumb img {
  margin-right: 8px;
}

.breadcrumb .lastcrumb {
  font-size: 12px;
  color: $grey_2;
}

.breadcrumb .selectable {
  user-select: all;
}

.chart-warning {
  font-size: 12px;
  color: $red;
}

.mainLabel {
  font-size: 36px;
}

.h-separator {
  width: 100%;
  height: 2px;
  background-color: #eeeeef;
  margin-bottom: 15px;
}

.fullsize {
  width: 100%;
  height: 100%;
}

k .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn {
  background-color: $blue_1;
  border-radius: 4px;
  border: hidden;
  color: white;
  padding: 8px 16px;
  min-height: 40px;
  cursor: pointer;
}

.btn:hover {
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.8);
  color: white;
}

.btn:disabled {
  background-color: $grey_3;
}

.btn:hover:disabled {
  box-shadow: none;
  cursor: not-allowed;
  transform: unset;
}

.btn-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  label {
    align-items: center;
    text-align: right;
  }

  * {
    width: 100%;
    border-radius: 0px 0px 0px 0px;
  }

  :first-child {
    border-radius: 4px 0px 0px 4px;
    height: 40px;
  }

  :last-child {
    border-radius: 0px 4px 4px 0px;
    height: 40px;
  }

  button,
  input {
    min-width: 250px;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  border: 4px solid $blue_3;
  border-radius: 2.5em;
  border-right-color: transparent;
  border-top-color: transparent;
  height: 5em;
  width: 5em;
  margin: auto;
  animation: spinAround 800ms infinite linear;
}

.fieldTitle {
  font-weight: 200;
}

.fieldValue {
  font-weight: 700;
}

/* Floating label input start */

.floatingField {
  position: relative;
  margin: 20px auto;

  .wronginput {
    display: block;
    line-height: 2em;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    font-size: medium;
    border: 1px solid white;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;

    &:focus {
      outline: none !important;
      border: 2px solid red;
    }
  }

  .wronglabel {
    color: red;
  }

  input {
    display: block;
    line-height: 2em;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    font-size: medium;
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;

    &:focus {
      border: 2px solid #3273dc;
      box-shadow: none;
    }
  }

  label {
    display: block;
    position: absolute;
    opacity: 0;
    bottom: 1.9rem;
    color: #5d5d5d;
    transition: 0.2s ease-in-out transform;
    font-size: 12px;
  }

  input:placeholder-shown + label {
    visibility: hidden;
    z-index: -1;
    transition: 0.2s ease-in-out;
  }

  input:not(:placeholder-shown) + label,
  input:focus:not(:placeholder-shown) + label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translateY(-10px);
    transition: 0.2s ease-in-out transform;
  }
}

/* Floating label input end */

/* Tablet breakpoint */
@media (max-width: 768px) {
  .ContentPane {
    padding: 6px 10px 6px 10px;
  }

  .btn-group {
    flex-direction: column;

    :first-child {
      width: 100%;
      border-radius: 4px 4px 0px 0px;
    }

    :last-child {
      width: 100%;
      border-radius: 0px 0px 4px 4px;
    }
  }
}

/* Phone breakpoint*/
@media (max-width: 480px) {
}
