.origin-type-listing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .mainLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: xx-large;
    font-weight: 400;
    margin: 16px;

    .originIcon {
      margin-right: 30px;
      max-width: 200px;
      max-height: 80px;
    }

    .originLabel {
      color: #56b5ce;
      font-weight: 600;
    }

    .countAndConfLabel {
      font-weight: 300;
      font-size: 30px;
      margin-top: 16px;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .listing {
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .cardFiller {
    flex-grow: 1;
    height: auto;
    width: 600px;
    background-color: transparent;
    border-radius: 10px;
    margin: 16px;
    padding: 5px 25px 25px 25px;
  }

  .fullLoader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #createBtn {
    align-self: end;
  }

  .headerInfos {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
  }

  .addOrigin {
    display: none;
  }

  .colHeader {
    display: flex;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .cardFiller {
    display: none;
  }

  #createBtn {
    display: none;
  }

  .colHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .origin-type-listing .addOrigin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $blue_1;
    font-size: 24px;
    line-height: 28px;
    color: white;

    &:hover {
      top: 14px;
      left: 14px;
      background-color: #00a2ce;
      color: white;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 769px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 1024px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1216px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1408px) {
}
