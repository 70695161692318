.SCConnTest {
  .top-bar {
    display: flex;
    .installed-from-badge {
      background-color: orange;
      color: white;
      border-radius: 4px;
      margin-left: auto;
      padding: 8px 16px;
    }
  }
}
