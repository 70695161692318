.editOrigin {
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.18);
  padding: 30px;

  .titleBar {
    display: flex;

    .originIcon {
      max-width: 200px;
      max-height: 50px;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 1.3em;
      margin-left: 32px;
    }
  }

  .stepContent {
    position: relative;
    margin: 25px 0px;
    height: calc(100% - 190px);
    border: solid 1px #eee;
    padding: 10px 20px;
  }

  .bottomBar {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .originName {
    font-size: 1.2em;
    font-weight: 500;
    margin-left: 6px;
  }

  @media (max-width: 1023px) {
    .stepContent {
      height: calc(100% - 205px);
      margin: 10px 0px;
    }
  }

  @media (max-width: 768px) {
    .titleBar {
      justify-content: center;
    }
    .subTitleBar {
      text-align: center;
    }
    .originName {
      text-align: center;
    }
  }
}
