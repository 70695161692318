@mixin media-min($_min-width) {
  @media screen and (min-width: $_min-width) {
    & {
      @content;
    }
  }
}

@mixin media-max($_min-width) {
  @media screen and (max-width: $_min-width) {
    & {
      @content;
    }
  }
}
