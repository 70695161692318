.filter-container {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: '. search activeOnly';
  align-content: center;
  justify-content: center;
  align-items: center;

  @include media-max(820px) {
    grid-row-gap: 1rem;
    grid-template-areas:
      'search search search'
      'activeOnly activeOnly .';
  }

  & .search {
    grid-area: search;

    & .search-input {
      width: 100%;
    }
  }

  & .active-only {
    grid-area: activeOnly;
    display: flex;
    justify-content: flex-end;

    @include media-max(820px) {
      justify-content: flex-start;
    }
  }
}
