// Tweak these to change the style of the toggle
$toggle-background-color-on: dodgerblue;
$toggle-background-color-off: darkgray;
$toggle-control-color: white;
$toggle-width: 40px;
$toggle-height: 20px;
$toggle-gutter: 2px;
$toggle-radius: 50%;
$toggle-control-speed: 0.15s;
$toggle-control-ease: ease-in;

// These are our computed variables
// change at your own risk.
$toggle-radius: $toggle-height / 2;
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

.toggle-control {
  display: flex;
  position: relative;
  padding-left: $toggle-width;
  cursor: pointer;
  user-select: none;
  flex-direction: row;
  align-content: center;
  height: $toggle-height;
  font-size: $toggle-height * 0.7;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .control {
    background-color: $toggle-background-color-on;

    &:after {
      left: $toggle-width - $toggle-control-size - $toggle-gutter;
    }
  }

  .control {
    position: absolute;
    top: 0;
    left: 0;
    height: $toggle-height;
    width: $toggle-width;
    border-radius: $toggle-radius;
    background-color: $toggle-background-color-off;
    transition: background-color $toggle-control-speed $toggle-control-ease;

    &:after {
      content: '';
      position: absolute;
      left: $toggle-gutter;
      top: $toggle-gutter;
      width: $toggle-control-size;
      height: $toggle-control-size;
      border-radius: $toggle-radius;
      background: $toggle-control-color;
      transition: left $toggle-control-speed $toggle-control-ease;
    }
  }

  .toggle-label {
    padding-left: $toggle-width * 0.2;
    line-height: $toggle-height;
    text-transform: capitalize;
  }
}
