.utils-container {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2rem;
}

.origin-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
