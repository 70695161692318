.stepIndicator {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  .stepDot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $blue_2;
    margin: 5px;

    &.navigable {
      cursor: pointer;
    }

    &.current {
      width: 25px;
      height: 25px;
      border: solid 3px lighten($blue_1, 10%);
    }

    &.finished {
      background-color: $blue_1;
    }

    &.blocked {
      cursor: not-allowed;
      background-color: $grey_3;
    }
  }
}
