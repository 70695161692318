.SC {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &.centered {
    justify-content: center;
  }

  .image-holder {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .feedbackHolder {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 8px;

    .saveError {
      color: red;
      font-weight: 500;
    }
  }

  .requiredFieldsLabel {
    font-size: 12px;
  }

  .validationBlock {
    width: 100%;
    display: flex;
    margin: 20px 0px;
    justify-content: center;
    align-items: center;

    .multipleFields {
      width: 50%;
    }

    @media (max-width: 1023px) {
      .multipleFields {
        width: 100%;
      }
    }
  }
}
