.originCard {
  display: grid;

  grid-template-columns: 1fr auto 1fr;
  grid-template-areas:
    'add . .'
    '. logo .'
    '. name .'
    'state state state';

  justify-content: center;
  align-items: center;

  width: 300px;
  height: 270px;

  margin: 1rem;
  padding: 1rem;

  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.09);

  vertical-align: top;

  cursor: pointer;

  transition: box-shadow 200ms ease-in-out;

  &.disable {
    grid-template-areas:
      '. coming .'
      '. logo .'
      '. name .';

    box-shadow: none;

    background-color: rgba(210, 210, 210, 0.34);

    cursor: auto;

    .comingSoon {
      grid-area: coming;
      color: #333;
      opacity: 1;
    }

    .originName {
      opacity: 0.3;
    }

    img {
      opacity: 0.5;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .originAdd {
    grid-area: add;

    width: 30px;
    height: 30px;
    line-height: 30px;

    border-radius: 5px;
    border: solid 1px #eee;

    font-size: 24px;

    color: $blue_3;

    transition: all 200ms ease-in-out;

    &:hover {
      background-color: #00a2ce;
      color: white;
    }
  }

  .originIcon {
    grid-area: logo;

    display: flex;
    align-items: center;

    width: 100%;
    height: 120px;

    img {
      height: inherit;
      object-fit: contain;
    }
  }

  .originName {
    grid-area: name;

    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #012b38;
  }

  .stateBlock {
    grid-area: state;

    bottom: 15px;

    .states {
      display: flex;

      width: 100%;

      flex-direction: row;
      justify-content: space-around;

      img {
        width: 15px;
        height: 15px;
      }

      div {
        display: flex;
        align-items: center;

        .stateLabel {
          margin-left: 5px;
          font-size: 16px;
          color: #aaa;
          padding-top: 2px;
        }

        &.active {
          filter: brightness(0) saturate(100%) invert(38%) sepia(95%)
            saturate(1586%) hue-rotate(92deg) brightness(92%) contrast(106%);
        }

        &.partiallyActive {
          filter: brightness(0) saturate(100%) invert(74%) sepia(38%)
            saturate(5588%) hue-rotate(345deg) brightness(101%) contrast(90%);
        }
      }
    }
  }

  &:hover {
    box-shadow: 9px 9px 10px -2px rgba(0, 0, 0, 0.08);
  }
}
